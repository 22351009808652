import { PaymentElement } from "@stripe/react-stripe-js";
import styles from "./stripe.module.css";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import useProtected from "../../../hooks/useProtected";
import {
  useConfirmPaymentMethodMainMutation,
  useConfirmPaymentMethodMutation,
  useUpdatePaymentStatusMutation,
} from "../../../redux/features/parentPortal/payments/paymentsApi";
import { toast } from "react-toastify";

export default forwardRef(function CheckoutForm(
  {
    refreshUser,
    clientSecret,
    authorizedFired,
    paymentSuccessful,
    setAuthorizedFired,
    defaultPaymentMethod,
    paymentMethodAddMode,
    setPaymentSuccessful,
    setPaymentSetUpDrawer,
    setShowSetupTopSticker,
    setPaymentMethodAddMode,
    setDefaultPaymentMethod,
    handleDrawerClosePayment,
    setAuthorizedCheckPaymentMethod,
  },
  ref
) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sucessStatus, setSucessStatus] = useState(false);

  const [updatePaymentStatus, updatePaymentStatusOptions] =
    useUpdatePaymentStatusMutation();
  const [confirmPaymentMethod, confirmPaymentMethodOptions] =
    useConfirmPaymentMethodMutation();
  const [confirmPaymentMethodMain, confirmPaymentMethodMainOptions] =
    useConfirmPaymentMethodMainMutation();

  useProtected(updatePaymentStatusOptions.error);
  useProtected(confirmPaymentMethodOptions.error);
  useProtected(confirmPaymentMethodMainOptions.error);

  useImperativeHandle(ref, () => ({
    async handleClick() {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "",
        },
        redirect: "if_required",
      });
      console.log(result, 500);
      if (result.error) {
        setMessage(result.error.message);
      } else {
        const paymentMethodId = result.setupIntent.payment_method;

        let data = {
          paymentMethodId,
        };

        confirmPaymentMethodHandler(data);

        setIsProcessing(false);
      }
    },
  }));

  const confirmPaymentMethodHandler = async (data) => {
    let result = paymentMethodAddMode
      ? await confirmPaymentMethodMain({
          ...data,
          isDefault: defaultPaymentMethod,
        })
      : await confirmPaymentMethod(data);

    if (result?.data && result?.data?.error != true) {
      setPaymentSetUpDrawer(false);

      if (!paymentMethodAddMode) {
        setShowSetupTopSticker(false);
        refreshUser();
      } else {
        setPaymentMethodAddMode(false);
        setDefaultPaymentMethod(false);
        setAuthorizedCheckPaymentMethod(false);
      }
      toast?.success("Bank Account added");
    } else {
      toast?.error("Something went wrong");
    }
  };

  const updatePayment = async (data) => {
    let result = await updatePaymentStatus(data);

    if (result?.data && result?.data?.error != true) {
      setSucessStatus(true);
      setPaymentSuccessful(true);
      handleDrawerClosePayment();
      // toast?.success("Paid successfully");
    } else {
      toast?.error("Something went wrong");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    let firstTwoFromClientSec = clientSecret.substring(0, 2);
    console.log(firstTwoFromClientSec, "firstTwoFromClientSec");
    if (firstTwoFromClientSec == "pi") {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "",
          // Specify redirect behavior
        },
        redirect: "if_required",
      });
      // console.log(paymentIntent, 345, error);
      if (paymentIntent && paymentIntent.status === "succeeded") {
        setMessage("Payment successful!");
        let data = {
          paymentIntentId: paymentIntent?.id,
        };
        updatePayment(data);
      }

      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
      setIsProcessing(false);
    } else {
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />

      {clientSecret.substring(0, 2) == "pi" && (
        <div className={styles.CheckOutFooter}>
          <button
            className={styles.PayNowButton}
            disabled={isProcessing || !stripe || !elements || sucessStatus}
            id="submit"
          >
            <span id="button-text">
              {isProcessing ? "Processing ... " : "Pay now"}
            </span>
          </button>
        </div>
      )}
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message" className={styles.CardNumberError}>{message}</div>} */}

      {clientSecret.substring(0, 2) == "pi" && (
        <div className={styles.CheckOutFooterTerms}>
          By continuing, you agree to our <a href="#">Terms of Service</a> and{" "}
          <a href="#">Privacy Policy.</a>
        </div>
      )}
    </form>
  );
});
